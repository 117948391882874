.tag_keyword{
    background-color: #e6e6e6;
    display: inline-block;
    margin:0px 0px;
    padding: 1px 8px;
    margin-left: 0px;
    margin-right: 0px;
    font-weight: 600;
    border-radius: 2px;
}
.all_chars{
    padding: 0px 4px;
    margin:1px 1px;
    margin-left: 6px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #9e9e9e;
    color: rgb(0, 0, 0);
    font-size: 10px;
}
.all_chars:hover{
    border: 1px solid #000000;
}
.selected_topic{
    margin:1px 1px;
    border: 3px solid #777777;
    background-color: #f0f0f0;
}