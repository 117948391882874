body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tooltip {	
  position: absolute;			
  text-align: center;
  width: 250px;													
  padding: 2px;				
  font: 12px sans-serif;		
  background: rgb(80, 79, 79);	
  border: 0px;		
  border-radius: 8px;			
  pointer-events: none;
  color: white;			
}
.make_cursor{
  cursor: pointer;
}
.cell_rect_selected{
 stroke: rgb(94, 94, 94);
 stroke-width:4px; 
}
mark{
  background: #fff387;
  color: black;
}
.tick line{
  stroke: #9e9e9e;
}
.tick text{
  fill: #636363;
  font-size: 10px;
}
.domain{
  stroke: #9e9e9e;
}
